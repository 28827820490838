@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.share-product-box {
  a {
    .v-icon.v-icon {
      font-size: 20px;
    }
    line-height: 30px;
    display: block;
    text-align: center;
    border-bottom: 1px solid #ebebeb;
    .facebook {
      color: #3b5999;
    }
    .pinterest {
      color: #d43232;
    }
    .linkedin {
      color: #0077b5;
    }
    .instagram {
      color: #a78966;
    }
    .twitter {
      color: #03a9f4;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
