@import "app/index.scss";

/*======banner slider=====*/
.home-carousel {
  &::v-deep .v-carousel {
    .v-carousel__controls {
      padding: 0 7px;
      bottom: 20px;
      height: 23px;
      width: auto;
      left: 50% !important;
      transform: translateX(-50%);
      border-radius: 20px;
      background-color: #fafafa;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

      .v-btn:before {
        display: none;
      }
      .v-carousel__controls__item {
        margin: 0;
        color: #bcbcbc;
        &.v-item--active {
          color: #ff9900;
        }
        .v-icon {
          width: 18px;
          opacity: 1;
          font-size: 14px !important;
        }
      }
      .v-btn--icon.v-size--small {
        height: auto;
        width: auto;
      }
    }
    .v-window__prev,
    .v-window__next {
      margin: 0;
      top: auto;
      bottom: 15px;
      background-color: #fff;
      @include transition(all, 400ms);
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
      &:hover {
        background-color: map-get($colors, brand);
        box-shadow: 0 3px 10px rgba(255, 153, 0, 0.4);
        .v-btn--icon.v-size--default {
          color: map-get($colors, light);
        }
      }
    }
    .v-window__prev {
      margin-left: -115px;
      left: 50% !important;
    }
    .v-window__next {
      margin-right: -115px;
      right: 50% !important;
    }
    .v-btn--icon.v-size--default {
      height: 32px;
      width: 32px;
      color: map-get($colors, primary);
      i {
        font-size: 25px !important;
      }
    }
  }
}
@include media(md) {
  .home-carousel {
    &::v-deep .v-carousel {
      .v-carousel__controls {
        height: 20px;
        bottom: 10px;
      }
      .v-window__prev,
      .v-window__next {
        bottom: 7px;
      }

      .v-btn--icon.v-size--default {
        height: 25px;
        width: 25px;
      }
    }
  }
}
