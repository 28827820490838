html {
  scroll-behavior: smooth;
}
body,
.v-application {
  @include font(text-color, 16px, 22px, regular);
  font-family: "Poppins", sans-serif;
  background-color: #fafafa;
}
/*==============================*/
/*=====   Common styles   ======*/
/*==============================*/
* {
  letter-spacing: normal !important;
}
a {
  color: map-get($colors, brand);
  text-decoration: none;
}
.v-application p,
p {
  padding: 0;
  margin: 0;
}
legend {
  display: none;
}
.v-text-field--outlined fieldset {
  top: 0;
}
.v-application a:hover {
  text-decoration: none;
  color: map-get($colors, brand);
}
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 12px;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
ul,
address {
  margin: 0;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
button {
  padding: 0;
  margin: 0;
  border: 0;
  &:focus {
    outline: 0;
  }
}
.v-btn {
  letter-spacing: normal;
}
.pointer {
  cursor: pointer;
}
.h-25 {
  height: 25%;
}
.h-50 {
  height: 50%;
}
.h-75 {
  height: 75%;
}
.h-100 {
  height: 100%;
}

/* Removing input type number spin arrow Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

/*==============================*/
/*=====  Main structure  =======*/
/*==============================*/

.container {
  margin-right: auto;
  margin-left: auto;
}
.row {
  margin: 0 -12px;
}
@include media(container) {
  .container {
    max-width: 1614px;
  }
  .home-product-sections {
    &.max-width {
      max-width: 1602px;
    }
  }
}

@include media(1639px) {
  .container {
    max-width: 95%;
  }
  .home-product-sections {
    &.max-width {
      max-width: 95%;
    }
  }
}
@include media(md) {
  body {
    padding-top: 50px;
  }
  .container {
    max-width: 100%;
  }
  .bidding-details-page {
    padding-bottom: 130px;
  }
}
.site-content-wrap {
  padding: rem-calc(20px 0);
}

/*==============================*/
/*==vuetify inputs auto height==*/
/*==============================*/
.v-text-field.v-text-field--solo .v-input__control {
  min-height: auto !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

// Vee form avalidation
.input-wrap {
  padding-bottom: rem-calc(12px);

  .input-label {
    padding-bottom: rem-calc(5px);
    color: darken(map-get($colors, primary), 10);
    @include font(false, 15px, 22px, false);
  }

  &.invalid {
    span {
      @include font(false, 12px, 14px, false);
      color: #ff0000;
    }
  }
  .v-input {
    input {
      height: 43px;
      max-height: 43px;
      padding: 0;
      @include font(primary, 15px, 43px, false);
    }
    fieldset {
      border: 1px solid lighten(map-get($colors, grey), 35);
      border-radius: 0;
    }
  }
  &.invalid {
    .v-input {
      fieldset {
        border-color: #ff0000;
      }
    }
  }
  .mdi-information-outline {
    margin: rem-calc(10px 0 0 10px);
  }
}
.phone {
  .input-wrap {
    .v-input__slot {
      padding-left: 0 !important;

      .iti--separate-dial-code {
        .iti__selected-flag {
          border-right: 1px solid lighten(map-get($colors, grey), 35);
        }
      }
    }
  }
}
