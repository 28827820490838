@import "app/index.scss";

.banner-wrapper {
  position: relative;
  .banner-caption {
    .v-btn {
      padding: rem-calc(8px 10px);
      .v-btn__content {
        span {
          @include font(false, 14px, 14px, false);
        }
      }
    }
    .banner-title {
      padding: rem-calc(4px 0 7px);
      @include font(primary, 50px, 60px, semi_bold);
    }
    p {
      padding-left: rem-calc(25px);
      position: relative;
      @include font(primary, 25px, 38px, semi_bold);

      &:before {
        @include sudo(4px, 60%, left, 0, top, 50%, brand);
        transform: translateY(-50%);
      }
    }
    .banner-btns {
      padding-top: rem-calc(36px);
      position: relative;

      .v-btn {
        padding: rem-calc(8px 20px);
        @include font(false, 16px, 48px, medium);

        &:not(:last-child) {
          margin-right: rem-calc(22px);
        }
      }
    }
  }
}
.home-seller-conrainer {
  border-radius: 10px;
  overflow: hidden;
}
.common {
  border-radius: 10px;
  overflow: hidden;
  .banner-caption {
    padding: rem-calc(20px 0);
  }

  .head-title {
    padding: rem-calc(16px 0);
    @include font(primary, 38px, 38px, semi_bold);
  }

  .banner-summery {
    padding: rem-calc(0 0 20px);
    display: block;
    @include font(false, 18px, 28px, medium);
  }

  .btn-text {
    padding: rem-calc(7px 5px);
    @include font(false, 16px, 22px, medium);
  }
  .container {
    padding: 0 5%;
  }
}

.common.middlepage {
  max-height: 222px;
}
.common.bottompage {
  max-height: 255px;
  border-radius: 0;
  text-align: center;
  background-color: #20242e;
  &::v-deep .v-image__image {
    background-image: none !important;
  }
}

@include media(lg) {
  .banner-wrapper {
    .banner-caption {
      .banner-title {
        &::v-deep br {
          display: none;
        }
        @include font(false, 40px, 50px, false);
      }
      p {
        &::v-deep br {
          display: none;
        }
        @include font(false, 20px, 30px, false);
      }
      .banner-btns {
        .v-btn {
          height: 2.6rem;
        }
      }
    }
  }
}

@include media(md) {
  .banner-wrapper {
    .banner-caption {
      .banner-title {
        padding-top: rem-calc(10px);
        @include font(false, 20px, 26px, false);
      }
      p {
        padding-left: rem-calc(10px);
        @include font(false, 16px, 20px, false);
      }
      .banner-btns {
        padding-top: rem-calc(15px);
        .v-btn {
          height: 2rem;
          padding: rem-calc(3px 10px);
          margin-bottom: rem-calc(4px);
          @include font(false, 14px, 14px, false);
        }
      }
    }
  }
}

@include media(sm) {
  .banner-wrapper {
    .banner-caption {
      padding: rem-calc(10px 0 30);

      .banner-title {
        padding-top: rem-calc(5px);
        @include font(false, 15px, 17px, false);
      }
      p {
        padding-left: rem-calc(10px);
        @include font(false, 14px, 16px, false);
      }

      .banner-btns {
        .v-btn {
          height: 1.5rem;
          padding: rem-calc(1px 10px);
          margin-bottom: rem-calc(4px);
          @include font(false, 12px, 14px, false);
        }
      }
    }
  }

  .common {
    .head-title {
      padding: rem-calc(10px 0);
      @include font(primary, 26px, 30px, semi_bold);
    }
    .banner-summery {
      padding: rem-calc(0 0 15px);
      display: block;
      @include font(false, 15px, 20px, medium);
    }
  }
}
