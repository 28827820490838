@import "app/index.scss";
.monile-menu {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 7;
  padding: rem-calc(10px 25px 10px 20px);
  background-color: map-get($colors, header_footer);
}
.menu-bar {
  width: 30px;
  height: 10px;
  margin-top: 3px;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  position: relative;

  &::after {
    @include sudo(20px, 2px, left, 0, bottom, -10px, light);
  }
}
.mobile-logo {
  width: 120px;
  margin-left: rem-calc(15px);
}

.monile-menu-top {
  .top-header-widgets {
    padding: 0;
    padding-right: rem-calc(25px);
  }

  .search-product {
    padding-right: rem-calc(80px);
    @include transition(500ms, all);
    @include absolute(100%, false, left, 0, top, -100%);
    background-color: map-get($colors, light);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.25);
    position: fixed;
    opacity: 0;
    z-index: 3;
    &::v-deep .v-input__slot {
      height: 50px;
    }

    &.show-search {
      top: 0;
      opacity: 1;
    }
    .close {
      @include absolute(50px, 50px, top, 0, right, 0);
      border-left: 1px solid lighten(map-get($colors, grey), 35);

      .v-icon {
        color: lighten(map-get($colors, grey), 0);
      }
    }
  }

  .head-search {
    &::v-deep .search-btn {
      @include absolute(false, false, top, 15px, right, 55px);

      .v-icon {
        color: lighten(map-get($colors, grey), 0);
      }
    }
  }

  &::v-deep .matched-wrap {
    padding-bottom: rem-calc(10px);
    background-color: map-get($colors, light);
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.12);
    @include absolute(100%, false, top, 50px, left, 0);
    border-top: 1px solid lighten(map-get($colors, grey), 35);

    ul {
      max-height: 200px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 0;
      margin: 0;
      @include scroll-y(4px);

      li {
        padding: rem-calc(8px 18px);
        display: flex;
        @include font(primary, 15px, 19px, false);

        .item-image {
          flex-shrink: 0;
          width: 44px;
          margin: -2px 0;
          margin-right: 12px;

          img {
            width: 100%;
          }
        }

        .item-info {
          padding: 3px 0 2px;
          text-align: left;

          .item-meta {
            margin-top: 1px;
            @include font(false, 13px, 15px, false);
            color: lighten(map-get($colors, grey), 10);
          }
        }

        .item-price {
          padding: 3px 0 2px;
          margin-left: 12px;
          flex-shrink: 0;
          white-space: nowrap;
          text-align: right;
          @include font(primary, 14px, 16px, bold);
        }
      }
    }
  }
}

.mobile-drawer {
  .sign-status {
    @include font(brand, 14px, 14px, false);
  }
  /*------category menu-----*/
  &::v-deep .category-menu {
    ul {
      padding: 0;
      margin: 0;
      li {
        a {
          padding: rem-calc(12px 12px);
          display: flex;
          align-items: center;
          color: map-get($colors, primary) !important;
          @include font(false, 16px, 22px, false);
          border-bottom: 1px solid lighten(map-get($colors, grey), 30);

          span {
            @include truncate-clamp(1);
          }

          img {
            padding-right: 8px;
          }
          i {
            display: none;
          }
        }
      }
    }
  }
  .language {
    @include font(grey, 16px, 22px, false);

    &::v-deep .v-input {
      .v-input__slot {
        padding: 0 6px 0 8px !important;
      }
    }
  }
}

.close-drawer {
  left: 253px;
  top: 14px;
  z-index: 9;
  position: fixed;
  animation-name: swap;
  opacity: 1;
  animation-duration: 0.4s;

  @keyframes swap {
    from {
      opacity: 0;
      left: 300px;
    }
    to {
      opacity: 1;
      left: 253px;
    }
  }
}
.v-menu__content {
  max-height: 95%;
}

@include media(479px) {
  .v-menu__content {
    .login-dropdown {
      width: auto;
    }
  }
  .monile-menu {
    padding: rem-calc(10px);
  }
  .monile-menu-top {
    .top-header-widgets {
      padding-right: rem-calc(18px);
    }
  }
  .menu-bar {
    width: 25px;
    height: 9px;
    margin-top: 7px;
    &::after {
      bottom: -9px;
    }
  }
  .mobile-logo {
    width: 100px;
    margin: rem-calc(4px 0 0 15px);
  }
}

/*All categories*/
.all-categories {
  .cat-text {
    padding: rem-calc(15px 12px 0);
    @include font(false, 20px, 26px, false);
  }
  .cat-holder {
    width: 230px;
    padding-top: rem-calc(10px);
  }
  .parent-category {
    border-top: 1px solid #d9d9d9;
    li {
      @include font(false, 14px, 20px, false);
      border-top: 1px solid darken(map-get($colors, light), 0);
      border-bottom: 1px solid darken(map-get($colors, light), 0);
      background-color: #fff;

      background-color: #fff;
      a {
        padding: rem-calc(6px 18px 6px 45px);
        display: block;
        background-color: #fff;
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        i {
          @include absolute(19px, 19px, left, 18px, top, 50%);
          transform: translateY(-50%);
          @include transition(all, 200ms);
        }
      }
    }
  }
}
