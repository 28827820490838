@import "app/index.scss";
.all-categories {
  padding-right: rem-calc(40px);
  position: relative;
  &:hover {
    .cat-holder {
      visibility: visible;
      top: 100%;
      opacity: 1;
    }
  }
  .cat-text {
    font-size: 15px;
  }
  .cat-holder {
    &.hide-cat {
      visibility: hidden;
      opacity: 0;
    }
  }
}

.cat-holder {
  width: 230px;
  padding-top: rem-calc(10px);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: all 400ms;
  @include absolute(false, false, left, 0, top, calc(100% + 20px));
}
.parent-category {
  width: 100%;
  padding: rem-calc(8px 0 10px);
  position: relative;
  background-color: map-get($colors, light);
  border: 1px solid darken(map-get($colors, light), 15);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.18);
  > li {
    @include font(false, 14px, 20px, false);
    border-top: 1px solid darken(map-get($colors, light), 0);
    border-bottom: 1px solid darken(map-get($colors, light), 0);
    background-color: #fff;

    > a {
      padding: rem-calc(6px 18px 6px 45px);
      display: block;
      background-color: #fff;
      position: relative;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      i {
        @include absolute(19px, 19px, left, 18px, top, 50%);
        transform: translateY(-50%);
        @include transition(all, 200ms);
      }
    }

    &:hover {
      @include font(false, 14px, 20px, bold);
      border-color: darken(map-get($colors, light), 15);

      > a {
        color: map-get($colors, brand);
        position: relative;
        z-index: 10;
        i {
          background-position: left bottom !important;
        }
      }

      .sub-categories {
        visibility: visible;
        left: 100%;
        opacity: 1;
        transition: all 500ms;
      }
    }
  }
}

/*=======sub-1 categories======*/
.sub-categories {
  width: 39vw;
  max-width: 750px;
  height: calc(100% + 2px);
  padding: 15px 0;
  margin-left: -1px;
  overflow-y: auto;
  @include scroll-y(6px);
  visibility: hidden;
  opacity: 0;

  background-color: map-get($colors, light);
  z-index: 1;
  @include absolute(false, false, left, 90%, top, 0);
  border: 1px solid darken(map-get($colors, light), 15);

  > ul {
    width: 100%;
    column-count: 3;
    -o-column-count: 3;
    -ms-column-count: 3;
    -webkit-column-count: 3;
    > li {
      padding: 0 7px 15px;
      display: grid;
      grid-template-rows: 1fr auto;
      -moz-column-break-inside: avoid;
      break-inside: avoid;

      > a {
        @include font(primary, 13px, 20px, semi_bold);
      }
    }
  }
}

.sub-sub-cat {
  a {
    display: block;
    @include transition(all, 300ms);
    @include font(false, 13px, 20px, light);

    &:hover {
      padding-left: 5px;
      text-decoration: underline;
      @include font(primary, 13px, 20px, medium);
    }
  }
}

@include media(1640px) {
  .sub-categories[data-v-659394e8] {
    width: 45vw;
  }
}
@include media(lg) {
  .all-categories[data-v-659394e8] {
    padding-right: rem-calc(20px);
    position: relative;
  }
  .sub-categories[data-v-659394e8] {
    width: 51vw;
  }
}
