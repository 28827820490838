@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import "@/sass/app/index.scss";
.flash-sale-time {
  .auction-time-holder {
    padding: rem-calc(5px 10px);
    background-color: #f1f3f6;
    @include border-radius(10px);
    .time-title {
      @include font(false, 16px, 26px, false);
    }
    .time-value {
      width: 35px;
      margin: rem-calc(0 5px);
      text-align: center;
      display: inline-block;
      background-color: map-get($colors, brand);
      @include font(light, 14px, 26px, medium);
    }
  }
}

.section-bg {
  padding: rem-calc(10px 20px);
  background-color: #fff;
  border-radius: 10px;
  &.no-bg {
    padding: 0;
    background-color: transparent;
  }
}
.home-block-wrapper::before,
.home-block-wrapper::after {
  flex-basis: 0;
  order: 1;
}

.home-product-sections {
  width: 100%;
  padding: rem-calc(0 6px 18px);
  &.grid-0 {
    padding-left: 0;
    padding-right: 0;
  }

  &.grid-6 {
    max-width: (1602px / 2);
  }
  &.grid-4 {
    max-width: (1602px / 3);
  }
  &.bottompage {
    padding-bottom: 0;
  }
  .home-sections-title {
    position: relative;
    .flash-sale-time {
      @include center(395px, false);
    }
    h2 {
      position: relative;
      padding-bottom: rem-calc(4px);
      margin-bottom: 5px;

      @include font(primary, 22px, 33px, medium);

      &:before {
        @include sudo(50px, 2px, left, 0, bottom, 0, brand);
      }
    }
    .v-btn.v-btn--outlined.v-btn--text {
      background-color: map-get($colors, light);
      border-color: map-get($colors, brand);
    }
  }
}
.grid-12:before,
.grid-12:after {
  flex-basis: 0;
  order: 1;
}

//for odd number

//for even number

@include media(1639px) {
  .section-bg {
    padding: rem-calc(10px 12px);
  }
  .home-product-sections {
    &.grid-6 {
      max-width: (95% / 2);
    }
    &.grid-4 {
      max-width: (95% / 3);
    }
  }
}
@include media(md) {
  .section-bg {
    padding: rem-calc(10px 12px);
  }
  .home-product-sections {
    &.grid-6 {
      max-width: (95%);
      &::v-deep .product-items {
        max-width: 33.33%;
      }
    }
    &.grid-4 {
      max-width: (95%);
    }
  }
}
@include media(md) {
  .home-product-sections {
    &.grid-6 {
      &::v-deep .product-items {
        max-width: 50%;
      }
    }
    .home-sections-title {
      position: relative;
      .flash-sale-time {
        padding: rem-calc(10px 0 8px);
        position: static;
        transform: none;
        width: 100%;
        display: flex;
      }
    }
  }
}
@include media(sm) {
  .home-product-sections {
    &.grid-6 {
      &::v-deep .product-items {
        max-width: 50%;
      }
    }
  }
}
@include media(479px) {
  .home-product-sections {
    .home-sections-title {
      h2 {
        font-size: 18px !important;
      }
    }
  }
}
