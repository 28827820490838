@import "app/index.scss";
.brand-container {
  padding-bottom: rem-calc(10px);
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  @include box-shadow(0, 0, 10px, 0, #d2d7db);
  h3 {
    padding: rem-calc(32px 7px 0);
    text-align: center;
    @include font(primary, 16px, 24px, regular);
  }
}
.brand-img {
  position: relative;
  img {
    width: 100%;
    display: block;
  }
}
.brand-logo {
  overflow: hidden;
  border: 1px solid #dfdfdf;
  @include border-radius(3px);
  transform: translateX(-50%);
  @include absolute(55px, 45px, left, 50%, bottom, -28px);
  img {
    display: block;
  }
}
