@import "app/index.scss";
.sections {
  padding-top: rem-calc(17px);
}
.section-bg {
  padding: rem-calc(10px 20px);
  background-color: #fff;
  border-radius: 10px;
}

.section-title {
  h2 {
    position: relative;
    padding-bottom: rem-calc(4px);

    @include font(primary, 22px, 33px, medium);

    &:before {
      @include sudo(50px, 2px, left, 0, bottom, 0, brand);
    }
  }
  // .v-btn.v-btn--outlined.v-btn--text {
  //     background-color: map-get($colors, light);
  //     border-color: map-get($colors, brand);
  // }
}
.category-container {
  height: 100%;
  border: 1px solid #ddd;
  padding: rem-calc(15px);
  h3 {
    padding-bottom: rem-calc(3px);
    @include truncate-clamp(1);
    @include font(primary, 16px, 22px, regular);
  }

  .see-more-box {
    text-align: right;
    .see-more {
      color: #646972;
      @include font(false, 14px, 22px, regular);
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.category-container-in {
  margin: rem-calc(0 -3px);
  .category-container-box {
    width: 50%;
    padding: rem-calc(3px);

    .discount {
      padding: 2px 6px 1px;
      @include font(light, 13px, 16px, medium);
      background-color: #e88b00;
      border: 1px solid #cc7a00;
    }

    a {
      display: block;
      position: relative;
      overflow: hidden;
      border: 1px solid #f1f3f6;
    }
  }
  &.item-1 {
    .category-container-box {
      width: 100%;
      padding: rem-calc(0);
      img {
        border: none;
      }
    }
  }
}

@include media(md) {
  .category-container {
    padding: rem-calc(10px);
  }
}
