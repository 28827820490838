@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import "@/sass/app/index.scss";

.maintanence {
  position: relative;
  text-align: center;
  padding: rem-calc(60px 15px 0 15px);
  .logo-container {
    width: 100%;
    padding: 5px 0;
    position: absolute;
    left: 0;
    top: 0;
    background-color: map-get($colors, primary);
    img {
      display: block;
      padding: 0;
      margin: 0 auto;
    }
  }
  h1 {
    padding-bottom: rem-calc(15px);
    @include font(false, 48px, 54px, primary);
  }
  p {
    @include font(false, 20px, 32px, grey);
  }
  img {
    max-width: 100%;
    padding-top: rem-calc(50px);
  }
}
@include media(md) {
  .maintanence {
    margin-top: -50px;
  }
}

@include media(sm) {
  .maintanence {
    h1 {
      @include font(false, 26px, 34px, primary);
    }
    p {
      @include font(false, 18px, 24px, false);
    }
  }
}
