@import "app/index.scss";

.add-post{
  padding:6px 15px 5px 9px;
  @include absolute(false, false, right, 10px, bottom, 20px);
  position: fixed;
  @include transition(all, 500ms);
  @include border-radius(20px);
  background-color: map-get($colors, brand);
  border: 1px solid map-get($colors, brand);
  z-index: 1;
  &:hover{
    background-color: map-get($colors, primary);
  }

  span{
    color: map-get($colors, light);
    .v-icon{color: map-get($colors, light);font-size: 19px;}
  }

  &:hover span .v-icon{
    
    color: map-get($colors, light);
  }
}

.site-footer {
  background-color: map-get($colors, header_footer);
  .footer-top {
    padding: rem-calc(44px 0);

    .footer-social .v-btn {
      width: 30px;
      height: 30px;
      display: inline-block;
      @include border-radius(3px);
      font-size: 17px;
      background-color: map-get($colors, primary);
      border-color: darken(map-get($colors, primary), 7);
      .v-icon {
        color: #fff;
        font-size: 17px;
      }
    }
    .footer-social {
      a {
        &:not(:last-child) {
          margin-right: rem-calc(4px);
        }
      }
    }
  }
  .footer-btm {
    background-color: #1c1f24;

    .mw-100 {
      max-width: 100%;
    }
    picture {
      order: 2;
    }
  }

  .footer-box {
    padding-top: 0;
    padding-bottom: 0;
    h3 {
      @include font(light, 22px, 28px, medium);
    }
    ul {
      padding: rem-calc(20px 0 0);
      li {
        padding: rem-calc(4px 0);
        @include font(light, 15px, 20px, false);

        a {
          position: relative;
          @include transition(all, 400ms);
          color: map-get($colors, light);

          &:before {
            @include transition(all, 300ms);
            @include sudo(0, 1px, left, 0, bottom, -2px, brand);
          }

          &:hover {
            margin-left: rem-calc(4px);
            color: map-get($colors, brand);

            &:before {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .copy {
    padding: rem-calc(7px 0);
    @include font(light, 14px, 22px, false);
    a {
      color: white;

      &:hover {
        color: map-get($colors, brand);
      }
    }
  }
  .e-cab {
    padding-top: 30px;
    h5 {
      @include font(light, 14px, 20px, medium);
    }
  }
}
@include media(1632px) {
  .site-footer {
    .footer-btm {
      picture {
        order: 0;
      }
    }
  }
}

@include media(lg) {
  .footer-top {
    padding: rem-calc(44px 0 44px);

    .footer-social .v-btn {
      width: 40px;
      height: 40px;

      &:not(:last-child) {
        margin-right: rem-calc(5px);
      }
    }
  }

  .footer-box {
    h3 {
      @include font(false, 18px, 24px, false);
    }
    ul {
      li {
        @include font(false, 14px, 20px, false);
      }
    }
  }
}

@include media(md) {
  .footer-top {
    padding: rem-calc(30px 0);
  }
  .footer-box {
    flex-basis: auto;
    width: 33.33%;
  }
  .footer-box:nth-child(1),
  .footer-box:nth-child(2) {
    width: 50%;
    padding-bottom: rem-calc(20px);
    margin-bottom: rem-calc(30px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }
}
@include media(sm) {
  .footer-box {
    width: 50%;
  }
  .footer-box:nth-child(3),
  .footer-box:nth-child(4) {
    padding-bottom: rem-calc(20px);
    margin-bottom: rem-calc(30px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }
  .copy {
    width: 100%;
    padding-top: 10px;
  }
  .spacer {
    display: none;
  }
  .add-post{
    right: auto;
    left: 50%;
    bottom: 5px;
    transform: translateX(-50%);
  }
}

@include media(479px) {
  .footer-top {
    .footer-social .v-btn {
      width: 30px;
      height: 30px;

      i {
        @include font(false, 19px, 20px, false);
      }
    }
  }
}
