@import "app/index.scss";
.list_box {
  height: 45px;
  padding-right: rem-calc(5px);
  border: 1px solid #dfdfdf;
  position: relative;
  border-radius: 30px;
  h3 {
    padding-left: rem-calc(52px);
    @include font(primary, 14px, 16px, regular);
    @include truncate-clamp(2);
  }
}
.list-img {
  overflow: hidden;
  border-radius: 50%;
  @include absolute(45px, 45px, left, -1px, top, -1px);
  border: 1px solid #dfdfdf;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

@include media(md) {
  .list-img {
    width: 40px;
    height: 40px;
  }
  .list_box {
    height: 40px;
    padding-right: 5px;
    h3 {
      padding: rem-calc(0 0 0 45px);
    }
  }
}
