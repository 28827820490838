@import "app/index.scss";

.notification-alert {
  position: relative;
  text-align: center;
  padding: rem-calc(15px 50px 15px 20px);
  background-color: #f1f1f1;
  span {
    color: #000;
    @include font(false, 17px, 22px, false);
    a {
      text-decoration: underline;
      font-weight: map-get($font-weight, semi_bold);
    }
  }
  &:after {
    @include sudo(100%, 4px, left, 0, bottom, 0, false);
    background-image: linear-gradient(90deg, #ff562f, #37bbb0);
  }

  .close {
    transform: translateY(-50%);
    @include absolute(false, false, right, 16px, top, 50%);
  }
}
header {
  background-color: map-get($colors, header_footer);
}
.top-header-wrapper {
  border-bottom: 1px solid #2d2d32;
}
.top-header {
  padding: rem-calc(15px 0);
}

.top-header-left {
  width: 34%;
  img {
    max-width: 137px;
  }
}
.top-header-mid {
  width: 40.5%;
  position: relative;
  .head-search {
    display: flex;
    .v-input__slot {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .search-btn {
    height: 43px;
    padding: 0 15px;
    @include transition(all, 400ms);
    @include font(false, 16px, 22px, false);
    @include border-radius(0 4px 4px 0);
    color: map-get($colors, light);
    background-color: map-get($colors, brand);

    i {
      @include font(light, 27px, 27px, false);
    }

    &:hover {
      background-color: darken(map-get($colors, brand), 5);
    }
  }

  .matched-wrap {
    padding-bottom: rem-calc(10px);
    z-index: 1;
    background-color: map-get($colors, light);
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.12);
    @include absolute(100%, false, top, 44px, left, 0);
    border-top: 1px solid lighten(map-get($colors, grey), 35);

    ul {
      max-height: 200px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 0;
      margin: 0;
      @include scroll-y(4px);

      li {
        padding: rem-calc(8px 18px);
        display: flex;
        @include font(primary, 15px, 19px, false);

        .item-image {
          flex-shrink: 0;
          width: 44px;
          margin: -2px 0;
          margin-right: 12px;

          img {
            width: 100%;
          }
        }

        .item-info {
          padding: 3px 0 2px;
          text-align: left;

          .item-meta {
            margin-top: 1px;
            @include font(false, 13px, 15px, false);
            color: lighten(map-get($colors, grey), 10);
          }
        }

        .item-price {
          padding: 3px 0 2px;
          margin-left: 12px;
          flex-shrink: 0;
          white-space: nowrap;
          text-align: right;
          @include font(primary, 14px, 16px, bold);
        }
      }
    }
  }
}
.top-header-right {
  width: 25.5%;
}
.site-search {
  position: relative;
  @include border-radius(6px);
  border: 2px solid #2d323b;

  .site-search-btn {
    @include transition(all, 400ms);
    @include font(false, 16px, 22px, false);
    @include absolute(117px, 100%, right, 0, top, 0);
    @include border-radius(0 4px 4px 0);
    color: map-get($colors, light);
    background-color: map-get($colors, brand);

    i {
      @include font(light, 27px, 27px, false);
    }

    &:hover {
      background-color: darken(map-get($colors, brand), 5);
    }
  }
}
.site-search-box {
  width: calc(100% - 117px);
  .v-text-field--outlined,
  .v-text-field--solo {
    border-radius: 4px 0 0 4px;
  }
}
.site-search-field {
  width: 100%;
  padding: rem-calc(10 125 9 17);
  margin: 0;
  border: 0;
  @include border-radius(4px);
  font-family: "Poppins", sans-serif;
  @include font(false, 16px, 25px, false);
  &:focus {
    outline: 0;
  }
}

.top-header-widgets {
  padding-left: rem-calc(30px);
  text-align: center;
  & > a {
    text-decoration: none;
    span {
      color: darken(map-get($colors, light), 15);
    }
  }
  span {
    padding-top: 2px;
    display: block;
    @include font(false, 12px, 12px, false);
    color: darken(map-get($colors, light), 15);
    &.sign-text {
      max-width: 70px;
      height: 18px;
      display: block;
      overflow: hidden;
      text-align: center;
      white-space: nowrap;
    }
  }
}
.header-widgets-icon {
  width: 20px;
  height: 20px;
  margin: 0 auto;
  position: relative;
  // border: 1px solid #fff;
  @include border-radius(50%);

  i.v-icon {
    @include center(false, false);
    color: map-get($colors, light);

    &.mdi-cards-heart {
      @include font(false, 25px, 25px, false);
    }
    &.mdi-cart {
      @include font(false, 23px, 23px, false);
    }
    &.mdi-account {
      @include font(false, 27px, 27px, false);
    }
    &.mdi-chevron-down {
      @include font(false, 19px, 19px, false);
      left: calc(100% + 5px);
      transform: translateY(-50%);
    }
  }

  .count {
    z-index: 1;
    color: #202227;
    @include border-radius(50%);
    @include font(false, 11px, 18px, medium);
    background-color: map-get($colors, brand);
    border: 2px solid map-get($colors, vulcan);
    @include absolute(22px, 22px, top, -8px, right, -18px);
  }
}

.select-box-wrapper {
  padding-right: rem-calc(11px);
}
.select-box {
  margin-right: rem-calc(20px);
  width: 107px;
  overflow: hidden;
  position: relative;

  .theme--dark.v-label {
    @include font(light, 14px, 16px, false);
  }
  .v-icon.v-icon {
    font-size: 20px;
  }
  .v-input__icon {
    height: 16px;
    min-width: 13px;
    width: 13px;
  }
}
.btm-header-wrapper {
  height: 33px;
  .v-toolbar {
    background-color: #2b2f39 !important;
  }

  .v-toolbar {
    height: auto;
    .v-toolbar__content {
      height: auto;
    }
  }
}
.main-menu {
  > ul {
    padding: 0;
    > li {
      padding: rem-calc(0 5px);
      display: inline-block;
      @include font(light, 15px, 33px, light);
      &:first-child {
        padding-left: 0;
      }
      a {
        padding: rem-calc(7px 5px 7px 32px);
        position: relative;

        &:hover {
          color: #fff;
          font-size: 15px;
          background: linear-gradient(
            to right,
            #00c3c3 10%,
            #b9f9cc 50%,
            #00eb7d 60%
          );
          background-size: auto auto;
          background-clip: border-box;
          background-size: 200% auto;
          color: #fff;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          animation: textclip 1.5s linear infinite;
        }
        img {
          width: 21px;
          @include absolute(false, false, left, 5px, top, 7px);
        }
        &.router-link-exact-active {
          border: 1px dashed #00e676;
          background-color: #242730;
          border-radius: 3px;
        }

        // &.router-link-exact-active:after {
        //   @include sudo(100%, 1px, left, 0, top, -14px, brand);
        // }

        i {
          @include font(brand, 12px, 12px, light);
          @include absolute(false, false, right, 9px, top, 2px);
          animation: blinker 1s linear infinite;
        }
        @keyframes blinker {
          50% {
            opacity: 0;
          }
        }
      }
      ul {
        display: none;
      }
    }
  }
  .more-menu {
    padding-left: rem-calc(15px);
    @include font(brand, 15px, 30px, light);
  }
}

@keyframes textclip {
  to {
    background-position: -200% center;
  }
}
.btm-header {
  .post-promotion {
    width: 142px;
    text-align: center;
    @include border-radius(6px);
    @include transition(all, 400ms);
    @include font(false, 16px, 26px, false);
    color: lighten(map-get($colors, silver_tree), 10);
    border: 1px solid darken(map-get($colors, primary), 50);
    background: rgb(43, 48, 55);
    background: linear-gradient(
      0deg,
      rgb(55, 59, 68) 0%,
      rgba(28, 30, 34, 1) 100%
    );
    .v-icon {
      color: lighten(map-get($colors, silver_tree), 10);
    }
    &:hover {
      background: darken(map-get($colors, primary), 50);
    }

    &:hover .v-icon {
      color: map-get($colors, brand);
    }
  }
}

.stiky_header {
  .header {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-animation: smoothScroll 1s;
    animation: smoothScroll 1s;
    background-color: map-get($colors, header_footer);
  }
  .stiky-height {
    height: 97px;
  }
  @keyframes smoothScroll {
    0% {
      transform: translateY(-97px);
      -webkit-transform: translateY(-97px);
    }

    100% {
      transform: translateY(0px);
      -webkit-transform: translateY(0px);
    }
  }
}

.v-menu__content {
  .login-dropdown {
    width: 300px;
    padding: rem-calc(20px 0 0);

    .v-list-item__title {
      font-size: 15px !important;
    }

    h5 {
      font-size: 15px;
    }
    .user-box {
      padding: rem-calc(0 20px 12px);
      border-bottom: 1px solid lighten(map-get($colors, grey), 35);

      a {
        width: 100%;
        height: 100%;
      }

      .user-title {
        span {
          @include font(primary, 14px, 14px, false);
        }
        h4 {
          @include truncate-clamp(1);
        }
        .verified {
          display: block;
        }
      }
    }
    .log-btns {
      padding: rem-calc(0 20px);
    }
    .v-list-item {
      padding: rem-calc(0);
    }
    .account-menus {
      .v-list-item {
        padding: rem-calc(0 20px);
      }
    }
  }
  .user-box {
    .v-avatar {
      border: 1px solid lighten(map-get($colors, grey), 25);
    }
  }
}

@include media(container) {
  .top-header-left {
    width: 32%;
  }
  .top-header-mid {
    width: 42.5%;
  }
  .select-box {
    margin-right: rem-calc(5px);
  }
  .select-box-wrapper {
    padding-right: 0;
  }
}

@include media(lg) {
  .site-search {
    .site-search-btn {
      width: 50px;
    }
    .site-search-box {
      width: calc(100% - 50px);
    }
  }
  .top-header-left {
    width: 37%;
  }
  .top-header-mid {
    width: 43%;
  }
  .top-header-right {
    width: 20%;
  }
  .select-box {
    width: 93px;
    .v-input__slot {
      padding: 0 5px !important;
    }
  }
  .top-header-widgets {
    span {
      display: none;
      &.sign-text {
        display: none;
      }
    }
  }
}

@include media(md) {
  .stiky_header {
    .stiky-height {
      height: 0;
    }
  }
}
