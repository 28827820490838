@import "app/index.scss";

.product-box {
  .v-sheet.v-card {
    @include border-radius(0);
    transition: all 500ms;
    padding-bottom: rem-calc(17px);
    border: 1px solid lighten(map-get($colors, grey), 38);
    &:hover {
      box-shadow: 0px 3px 12px 0px rgb(0, 0, 0, 0.15) !important;
    }
  }
  .product-image-box {
    position: relative;
    background-color: #f8f8f8;
    border-bottom: 1px solid lighten(map-get($colors, grey), 40);
  }
  &:hover {
    .product-top-right {
      right: 10px;
    }
    .product-title a {
      color: map-get($colors, brand);
    }
  }
}
.product-sub-title {
  padding-bottom: rem-calc(4px);
  padding-top: rem-calc(20px);
  span {
    @include font(false, 13px, 18px, false);
    @include truncate-clamp(1);
    text-overflow: ellipsis;
  }
}

.product-top-left,
.product-top-right {
  transition: all 400ms;
  .v-btn {
    width: rem-calc(28px);
    height: rem-calc(28px);

    i.mdi {
      @include font(false, 16px, 16px, false);
    }
  }
  .v-btn:not(:last-child) {
    margin-right: rem-calc(10px);
  }
}
.product-top-right {
  @include absolute(false, false, right, -100px, top, 10px);
}
.product-top-left {
  @include absolute(false, false, left, 10px, top, 10px);
  top: 10px;
  .sold {
    width: 70px;
  }
  
}
// .condition {
//   padding: 0 5px;
//   margin-bottom: 5px;
//   background-color: #fdfdfd;
//   // box-shadow: 0 0 3px 2px rgba(34, 36, 40, 0.15);
//   border-bottom-right-radius: 7px;
//   border: 1px solid lighten(map-get($colors, grey), 25);
//   span {
//     padding: 0 2px;
//     display: inline-block;
//     @include font(false, 13px, 14px, false);
//   }
// }
.condition-wrap{
  display: flex;
  flex-direction: column;

  .condition {
    padding: 4px 8px;
    margin-bottom: 5px;
    text-align: center;
    @include border-radius(3px);
    @include font(light, 14px, 16px, medium);
    // color: map-get($colors, light);
    // 
    // @include border-radius(3px);
    &.type{
      background-color: map-get($colors, silver_tree);
    }
    &.lot{
      background-color: #00c9a2;
    }

     &.discount {
      background-color: darken(map-get($colors, brand), 4.5);
      border: 1px solid darken(map-get($colors, brand), 10);
    }
    &.emi{
      width: 42px;
      background-color: map-get($colors, silver_tree);
    }
  }
}
.product-btm-labels {
  @include absolute(false, false, right, 0, bottom, -1px);
  .type {
    padding: rem-calc(1px 8px);
    border-top-left-radius: 7px;
    @include font(false, 12px, 14px, false);
  }
}
.product-information {
  padding-bottom: rem-calc(0);
}

.product-title {
  height: 48px;
  padding-bottom: rem-calc(8px);
  @include font(primary, 14px, 20px, regular);

  a {
    @include truncate-clamp(2);

    &:hover {
      text-decoration: underline !important;
    }
  }
}

.product-price-box {
  align-items: center;
  .product-price {
    padding-right: rem-calc(10px);
    display: inline-block;
    @include font(brand, 16px, 28px, medium);
  }
  .bidding-status {
    @include font(grey, 14px, 28px, false);
    text-overflow: ellipsis;
    @include truncate-clamp(1);
  }
}
.product-location {
  padding-left: rem-calc(17px);
  position: relative;
  @include truncate-clamp(1);
  @include font(gray, 14px, 20px, false);

  i.v-icon {
    @include absolute(false, false, left, -2px, top, 0);
    @include font(gray, 17px, 20px, false);
  }
}
.product-bottom {
  padding: rem-calc(3px 10px);
  @include border-radius(3px);
  @include box-shadow(0, 1px, 2px, 1px, rgba(34, 36, 40, 0.13));
  transform: translateX(-50%);
  @include absolute(94%, false, left, 50%, bottom, -13px);
  z-index: 1;
  background-color: map-get($colors, light);

  .auction-time-holder {
    > span {
      display: flex;
      align-items: center;
      @include font(secondary, 13px, 18px, false);
    }
    .time-title {
      white-space: nowrap;
      padding-right: rem-calc(10px);
      display: inline-block;
      @include font(gray, 14px, 18px, light);
    }
    .time-value {
      padding-right: rem-calc(5px);
      // white-space: nowrap;
      // overflow: hidden;
      @include font(secondary, 13px, 18px, medium);
      text-overflow: ellipsis;
      @include truncate-clamp(1);
    }
  }
}

.auction-bid-holder {
  white-space: nowrap;
  .bid-title {
    padding-right: rem-calc(10px);
    display: inline-block;
    @include font(primary, 14px, 18px, light);
  }
  .bid-value {
    display: inline-block;
    @include font(secondary, 16px, 18px, medium);
  }
}

.sell-btn-wrap {
  padding: rem-calc(0 0 70px 30px);
  .sell-btn.v-btn {
    height: 46px;
    @include font(false, 16px, 22px, semi_bold);

    i {
      @include font(false, 18px, 22px, false);
    }
    &:hover {
      color: map-get($colors, light);
    }
  }
}

.share-product-wrap {
  position: relative;

  &.share-product-grid {
    width: 30px;
    height: 20px;
    display: flex;
    cursor: pointer;
    text-align: center;
    position: relative;

    &::after {
      @include sudo(0, 0, left, 100%, top, 50%, false);
      transform: translateY(-50%);
      border-top: 4px solid transparent;
      border-left: 8px solid #fff;
      border-bottom: 4px solid transparent;
    }
    &:hover {
      box-shadow: 0 0 16px rgba(62, 62, 60, 0.4);
    }

    .v-icon {
      font-size: 18px;
      line-height: 20px;
      margin: auto;
    }
  }

  .share-product {
    width: 30px;
    border-radius: 0 !important;
  }

  .share-productIn {
    max-width: none;
    padding-left: 10px;
    z-index: 3;
    @include absolute(false, false, left, 100%, top, 0);
  }
}

@include media(1639px) {
  .product-card {
    .v-card__text {
      padding: rem-calc(0 10px);
    }
  }

  .product-price-box {
    .product-price {
      @include font(false, 12px, 24px, false);
    }
    .bidding-status {
      @include font(false, 10px, 20px, false);
    }
    .discount-amount {
      font-size: 10px;
    }
  }

  .sell-btn-wrap {
    .sell-btn.v-btn {
      height: 38px;
    }
  }
}

@include media(1400px) {
  .product-top-left {
    .sold {
      width: 60px;
      margin-top: 7px;
    }
  }
  .product-bottom {
    .auction-time-holder {
      .time-value {
        @include font(secondary, 12px, 18px, medium);
      }
    }
  }
}

@include media(md) {
  .product-btm-labels {
    span {
      line-height: rem-calc(18px);
    }
  }

  .product-top-labels {
    .v-btn {
      width: rem-calc(25px);
      height: rem-calc(25px);

      i.mdi {
        @include font(false, 15px, 25px, false);
      }
    }
    .v-btn:not(:last-child) {
      margin-right: rem-calc(5px);
    }
  }
  .v-sheet.v-card {
    .product-sub-title {
      line-height: 16px;
    }
    .product-title {
      padding-bottom: rem-calc(7px);
      @include font(false, 14px, 20px, false);
    }
  }

  .product-price-box {
    .product-price {
      @include font(false, 16px, 24px, false);
    }
  }
  .product-information {
    padding-bottom: rem-calc(10px);
  }

  .sell-btn-wrap {
    padding: rem-calc(0 0 70px 10px);
  }
  .product-bottom {
    .bid-and-time{
      flex-wrap: wrap;
      .auction-time-holder{
        width: 100%;
      }
    }
  }
}

@include media(599px) {
  .product-items {
    width: 50%;
    padding: 4px;
  }
  .sell-btn-wrap {
    padding: rem-calc(0 0 70px 10px);
    .sell-btn.v-btn {
      @include font(false, 14px, 22px, false);
    }
  }
  /*===19/09/2019====*/
  .v-sheet.v-card {
    .product-title {
      padding-bottom: rem-calc(5px);
      height: 40px;

      a {
        @include font(false, 14px, 17px, false);
      }
    }
  }
  .condition {
    padding-bottom: 0;
    span {
      @include font(false, 15px, 24px, false);
    }
  }
  .product-price-box {
    .product-price {
      padding-top: rem-calc(5px);
      @include font(false, 16px, 16px, false);
    }
  }
  .product-information {
    padding-bottom: rem-calc(0);
  }
  .product-bottom {
    padding: rem-calc(1px 5px);
    .auction-time-holder {
      font-size: 12px;
      line-height: 14px;
      & > span {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}
@include media(479px) {
  .product-price-box {
    height: 45px;

    flex-wrap: wrap;
    .product-price {
      width: 100%;
      padding-top: rem-calc(5px);
      @include font(false, 16px, 16px, false);
    }
  }
  .rating span {
    font-size: 13px;
  }
}

@include media(374px) {
  // .product-items {
  //     width: 100%;
  // }

  .sell-btn-wrap {
    padding: 104% 0 50px 16px;
  }
  .product-card {
    .v-card__text {
      padding: rem-calc(0 6px);
    }
  }

  .v-sheet.v-card {
    .product-title {
      padding: 0;
      height: 35px;

      a {
        @include font(false, 13px, 16px, false);
      }
    }
  }
  .product-sub-title {
    span {
      @include font(false, 13px, 17px, false);
    }
  }
  .condition {
    padding-bottom: 0;
    span {
      @include font(false, 14px, 17px, false);
    }
  }
  .product-price-box {
    height: 45px;
    .product-price {
      padding-top: rem-calc(5px);
      @include font(false, 16px, 16px, false);
    }
  }
  .product-information {
    padding-bottom: rem-calc(0);
  }
  .v-icon.v-icon.v-icon--link {
    font-size: 12px !important;
  }
  .rating {
    span {
      font-size: 12px;
    }
  }
}
.discount-amount {
  color: #06c9a5;
  font-size: 12px;
}
