@import "app/index.scss";
.home-group {
  margin: rem-calc(0 -8px);
}
.group-item-wrap {
  justify-content: center;
}
.group-item {
  width: 16.66%;
  padding: rem-calc(0 8px);
  &:nth-child(3n + 1) {
    .group-item-icon {
      background-color: #00c9a2;
    }
  }
  &:nth-child(3n + 2) {
    .group-item-icon {
      background-color: #f89921;
    }
  }
  &:nth-child(3n + 3) {
    .group-item-icon {
      background-color: #00b6f1;
    }
  }
}
.group-item-bg {
  height: 45px;
  padding-right: rem-calc(5px);
  border-radius: 25px 22px 0 25px;
  background-color: #fff;
  align-items: center;
  span {
    padding-left: 13px;
    @include font(primary, 14px, 16px, medium);
    @include truncate-clamp(2);
  }
}
.group-item-icon {
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;

  .v-image {
    @include center(false, false);
  }
}

@include media(md) {
  .group-item {
    width: 33.33%;
    padding: rem-calc(5px 8px);
  }
}
@include media(sm) {
  .home-group {
    margin: 0;
  }
  .group-item {
    padding: 0 0 7px;
  }
  .group-item-bg {
    height: auto;
    padding: 0;
    flex-wrap: wrap;
    justify-content: center;
    background: transparent;

    span {
      padding: 3px 0 0;
      width: 100%;

      text-align: center;
    }
  }
}
@include media(420px) {
}
